



































































import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { EmiratisationStatsResponse } from "@/interfaces/responses/statistics";
import { EmiratisationStatsGraphData } from "@/interfaces/recruiter/dashboard/recruiter_dashboard";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { calculate_percentage } from "@/utils/global";

export default Vue.extend({
  name: "EmiratisationStatistics",
  components: {
    DataNotFound,
    // DataNotFound
    "vue-apex-charts": VueApexCharts
  },
  data() {
    return {
      emiratisation_statistics_graph_series:
        [] as EmiratisationStatsGraphData[],
      emiratisation_statistics_graph_options: {}
    };
  },
  props: {
    stats: {
      type: Object as PropType<EmiratisationStatsResponse>,
      required: true
    },
    stat_error: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    this.intialize_emiraisation_option();
    setTimeout(() => {
      this.emiratisation_statistics_graph_series = [
        {
          name: this.$t("recruiter.dashboard.emiratisation-title").toString(),
          data: [
            calculate_percentage(
              this.stats.local_count,
              this.stats.total_users
            ),
            calculate_percentage(
              this.stats.foreign_count,
              this.stats.total_users
            )
          ]
        }
      ];
    }, 1000);
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  watch: {
    get_site_direction() {
      this.intialize_emiraisation_option();
    },
    "$vuetify.breakpoint.xsOnly": function () {
      this.intialize_emiraisation_option();
    }
  },
  methods: {
    get_title() {
      this.$t("shared.failed-data");
    },
    intialize_emiraisation_option() {
      this.emiratisation_statistics_graph_options = {
        colors: ["#FF8473", "#EE49FD"],
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },
        tooltip: {
          enabled: false,
          position: "bottom"
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
            borderRadiusApplication: "end",
            barHeight: "20px",
            distributed: true
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [this.$t("shared.locals"), this.$t("shared.foreigners")],
          min: 0,
          max: 100,
          labels: {
            show: this.$vuetify.breakpoint.xsOnly ? false : true,
            formatter: function (value: number) {
              return value + "%";
            },
            style: {
              colors: ["#334466"],
              fontSize: "12px",
              fontFamily: "Roboto",
              fontWeight: 400
            }
          }
        },
        yaxis: {
          labels: {
            show: this.get_site_direction === SiteDirections.LTR ? true : false,
            style: {
              colors: ["#3F5073"],
              fontSize: "14px",
              fontFamily: "Roboto",
              fontWeight: 500
            }
          }
        },
        grid: {
          show: true,
          borderColor: "#ffffff",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        }
      };
    }
  }
});
