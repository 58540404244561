





























































import Vue, { PropType } from "vue";
import {
  DashboardStatistics,
  StatisticsResponse
} from "@/interfaces/recruiter/dashboard/dashboard_statistics";
import VueApexCharts from "vue-apexcharts";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import moment from "moment";
import { clean_string } from "@/utils/global";
import { MembersJobsStatistics } from "@/store/modules/recruiter/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "DashboardStatistics",
  components: {
    DataNotFound,
    "vue-apex-charts": VueApexCharts
  },
  data(): DashboardStatistics {
    return {
      loading: false,
      data_dropdown_items: [],
      date_time_dropdown_items: [],
      data_dropdown_selection: this.$t(
        "recruiter.dashboard.stats-op1"
      ).toString(),
      date_time_dropdown_selection: this.$t("shared.weekly").toString(),
      chartOptions: {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },

        xaxis: {
          type: "datetime",
          format: "MMM d"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            endingShape: "rounded"
          }
        }
      },
      chartData: {
        series: []
      }
    };
  },
  props: {
    stat_error: {
      type: Boolean,
      required: true
    },
    stat_loading: {
      type: Boolean,
      required: true
    },
    members_data: {
      type: Array as PropType<StatisticsResponse[]>,
      required: true
    },
    jobs_data: {
      type: Array as PropType<StatisticsResponse[]>,
      required: true
    }
  },
  watch: {
    async get_site_direction() {
      this.chartData.series = [];
      await this.init_data();
    }
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  async mounted() {
    await this.init_data();
  },
  methods: {
    getTitle() {
      this.$t("shared.failed-data");
    },
    async init_data() {
      this.data_dropdown_items = [
        this.$t("recruiter.dashboard.stats-op1").toString()
      ];
      this.date_time_dropdown_items = [
        this.$t("shared.weekly").toString(),
        this.$t("shared.monthly").toString()
      ];
      this.data_dropdown_selection = this.$t(
        "recruiter.dashboard.stats-op1"
      ).toString();
      this.date_time_dropdown_selection = this.$t("shared.weekly").toString();
      const filter = sessionStorage.getItem("filter");
      if (
        filter &&
        (clean_string(filter) === "weekly" ||
          clean_string(filter) === "monthly")
      )
        this.date_time_dropdown_selection = this.$t(
          `shared.${clean_string(filter)}`
        ).toString();
      if (this.members_data.length > 0) {
        this.chartData.series.push({
          name: this.$t("shared.members").toString(),
          data: this.members_data.map((data: StatisticsResponse) => [
            moment(data.day).valueOf(),
            data.count
          ])
        });
      }
      if (this.jobs_data.length > 0) {
        this.chartData.series.push({
          name: this.$t("shared.jobs").toString(),
          data: this.jobs_data.map((data: StatisticsResponse) => [
            moment(data.day).valueOf(),
            data.count
          ])
        });
      }
    },
    getLast7Days() {
      const days = [];
      const start_date = moment().startOf("month");
      // Loop through the days of the week and add them to the array
      for (let i = 0; i < 6; i++) {
        days.push(start_date.clone().add(i, "days").format("MMM D"));
      }

      return days;
    },
    update_dashboard_statistics(value: string) {
      const input = clean_string(value);
      if (input === "weekly" || input === "أسبوعيًا") {
        const end_date = moment().add(1, "day");
        const start_date = moment().subtract(7, "days");
        const payload: MembersJobsStatistics = {
          filter: clean_string("weekly"),
          start_date: start_date.format("YYYY-MM-DD"),
          end_date: end_date.format("YYYY-MM-DD")
        };
        this.$emit("update_stat", payload);
        this.date_time_dropdown_selection = this.$t(`shared.weekly`).toString();
        sessionStorage.setItem("filter", "Weekly");
      } else if (input === "monthly" || input === "شهريًا") {
        const end_date = moment().add(1, "day");
        const start_date = moment().subtract(30, "days");
        const payload: MembersJobsStatistics = {
          filter: clean_string("weekly"),
          start_date: start_date.format("YYYY-MM-DD"),
          end_date: end_date.format("YYYY-MM-DD")
        };
        this.$emit("update_stat", payload);
        this.date_time_dropdown_selection =
          this.$t(`shared.monthly`).toString();
        sessionStorage.setItem("filter", "Monthly");
      }
    }
  }
});
